body {
  margin: 0;
  background-color: #E7EAD7;
  color: #272727;
  font-family: sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
